/* .sidebar {
    margin: 0;
  padding: 0;
  width: 250px;
  background-color: #f1f1f1;
  position: fixed;
  height: 100%;
  overflow: auto;
}

.sidebar a {
    display: block;
  color: black;
  padding: 7px;
  text-decoration: none;
}

.sidebar a.active {
    background-color: #04AA6D;
    color: white;
  }

  .sidebar a:hover:not(.active) {
    background-color: #555;
    color: white;
    transition: 0.5s;
    border-radius: 25px;
  }

  div.content {
    margin-left: 250px;
    padding: 1px 16px;
    height: 1000px;
  }

  @media screen and (max-width: 700px) {
    .sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }
    .sidebar a {float: left;}
    div.content {margin-left: 300;}
}

@media screen and (max-width: 400px) {
    .sidebar a {
      text-align: center;
      float: none;
    }
  }

  .sidebarLink {
    margin-top: 20vh;
  } */

  .table-resp {
    overflow-x: scroll;
  }
  .table-box{
    overflow-x: scroll !important;
  }
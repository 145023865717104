.sidebar {
    margin: 0;
  padding: 0;
  width: 250px;
  /* background-color: #f1f1f1; */
  background-color: white;

  position: fixed;
  height: 100%;
  overflow: none;
}

.sidebar a {
    display: block;
  padding: 7px;
  font-size: medium;
  text-decoration: none;
  
}

.sidebar a.active {
    background-color: #f3f4f9;
    color: rgba(0, 0, 0, 0.82) !important;
    border-radius: 10px;
  }

  .sidebar a:hover:not(.active) {
    background-color:#dee1ee;
    transition: 0.8s;
    border-radius: 10px;
    color: black !important;

  }

  div.content {
    margin-left: 250px;
    padding: 1px 16px;
    /* height: 1000px; */
    background-color: #f3f4f9;
    border-radius: 50px;
  }
  .btn-nav{
    display: none;
  }
  .side-Link {
    margin: 10px;
   
  }
  

  
  @media screen and (max-width: 700px) {
    .sidebar {
      width: 50px;
    }
    .sidebar-toggle   {
      display: block;
   
    }
    .iconName {
      display: none !important;
    }
  
    div.content {
      margin-left: 50px;
    }
    .side-Link {
      margin: 10px;
    }
    .card {
      overflow-x: scroll;
      background-color: black;
    }
  }




  .sideBarMenu:hover {
    font-size: larger;
    transition: .5s;
    color: gray;
  }
  .sidebar-toggle  {
    display: none;
    position: fixed;
    top: 40px;
    left: 0;
    z-index: 9999;
  }

  /* @media screen and (max-width: 700px) {
    .sidebar {
      width: 100%;
      height: auto;
      position: relative;
      border-radius: 0%;
    }
    .sidebar a {float: left;}
    div.content {margin-left: 0;}
    .btn-nav{
      display: block;
    }
    .sidebarLink{
      display: flex;
      flex-direction: column;
      margin-top: 0%;
    }
    .sidebar_hidden{
      display: none;
     
    }
    
    
} */


/* @media screen and (min-width: 700px) {
  .sideBarIcon{
   display: none;
  }
    
  } */

  .sidebar_hidden {
    margin-top: 19vh;
  }


  .sidebar, .sidebar_hidden, .sidebarLink{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .sidebar{
    padding-bottom: 1rem;
  }
  .sidebar-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }
  


   